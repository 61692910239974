<template>
  <v-app id="app">
    <Header v-if="!isMobile" />
    <HeaderMobile v-if="isMobile" />
    <div class="wrapper" :style="isMobile ? 'margin-top: 65px' : ''">
      <router-view />
    </div>
    <Footer v-if="showFooter && !isMobile" />
    <FooterMobile v-if="showFooter && isMobile" />
  </v-app>
</template>

<script>
import Header from "@/components/Page/Header.vue";
import HeaderMobile from "@/components/Page/HeaderMobile.vue";
import Footer from "@/components/Page/Footer.vue";
import FooterMobile from "@/components/Page/FooterMobile.vue";

export default {
  name: "App",
  components: {
    Header,
    HeaderMobile,
    Footer,
    FooterMobile,
  },
  data() {
    return {
      showFooter: false,
      cookieAccepted: false,
      isMobile: false,
    };
  },
  updated() {
    this.toggleFooter();
  },
  created() {
    this.isMobile = window.innerWidth <= 800;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 800;
    });
  },
  methods: {
    toggleFooter() {
      if (window.location.pathname == "/explore") {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
    },
  },
};
</script>

<style>
.wrapper {
  height: 100%;
}
</style>

<style lang="css">
@import "./style/front.css";
</style>
