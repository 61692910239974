<template>
  <footer style="padding: inherit">
    <v-container>
      <v-row align="center" no-gutters>
        <v-col cols="5">
          <v-img
            style="float: right"
            contain
            :src="require('@/assets/images/Logo-icon-klover.png')"
            :lazy-src="require('@/assets/images/Logo-icon-klover.png')"
            max-height="10vh"
            max-width="60%"
          />
        </v-col>
        <v-col>
          <v-list class="footer-mobile-list">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="white--text"
                  ><p class="footer-mobile-title">Klover</p></v-list-item-title
                >
                <v-list-item-subtitle class="white--text">
                  <p class="footer-mobile-title">By Kermap</p>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <a
                    class="footer-mobile-kermap-link"
                    href="https://kermap.com/"
                    >kermap.com</a
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col
          ><p class="footer-mobile-links">
            <a href="/privacy">Privacy - Terms of use</a> -
            <a>©2020 Kermap</a>
          </p></v-col
        >
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: "FooterMobile",
};
</script>

<style scoped>
.footer-mobile-title {
  font-size: 16px;
}

.footer-mobile-list {
  background: inherit !important;
}

.footer-mobile-kermap-link {
  color: #8998af;
  text-decoration: inherit;
  font-size: 14px;
}

.footer-mobile-links {
  color: #8998af;
  font-size: 10px;
  text-align: center;
}

.footer-mobile-links a {
  color: inherit;
  text-decoration: inherit;
}
</style>
