import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeRedirect",
    component: () => import("@/components/Home/HomeRedirect"),
  },
  {
    path: "/accueil",
    name: "Accueil",
    component: () => import("@/components/Home/Home"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/components/Home/Home"),
  },
  {
    path: "/explore",
    name: "ExploreRedirect",
    component: () => import("@/components/Explore/ExploreRedirect"),
  },
  {
    path: "/explorer",
    name: "ExplorerRedirect",
    component: () => import("@/components/Explore/ExploreRedirect"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/components/About"),
  },
  {
    path: "/propos",
    name: "À Propos",
    component: () => import("@/components/About"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/components/Contact"),
  },
  {
    path: "/featured",
    name: "Featured",
    component: () => import("@/components/Featured/Featured"),
  },
  {
    path: "/classement",
    name: "Classement",
    component: () => import("@/components/Featured/Featured"),
  },
  {
    path: "/compare",
    name: "Compare",
    component: () => import("@/components/Compare/Compare"),
  },
  {
    path: "/comparer",
    name: "Comparer",
    component: () => import("@/components/Compare/Compare"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/components/Legal/Privacy"),
  },
  {
    path: "/:name",
    name: "City",
    component: () => import("@/components/City/City"),
  },
  {
    path: "/ville/:name",
    name: "Ville",
    component: () => import("@/components/City/City"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
