<template>
  <header>
    <v-app-bar style="height: 65px" fixed elevate-on-scroll color="#eeedeb">
      <v-container fill-height>
        <v-row align="center">
          <v-btn
            href="/"
            text
            class="header-btn ma-2 mb-1"
            width="115px"
            :ripple="false"
          >
            <v-img
              :src="require('@/assets/images/Klover_logo_mobile.webp')"
              :lazy-src="require('@/assets/images/Klover_logo_mobile.webp')"
              max-width="10%"
              max-height="10%"
              contain
            />
          </v-btn>
          <v-spacer />
          <v-btn
            class="header-mobile-menu-burger"
            large
            icon
            color="#113589"
            @click="toggleDrawer()"
          >
            <v-icon color="#113589">mdi-menu</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app color="#eeedeb" right temporary>
      <v-container fluid>
        <v-row class="mt-2">
          <v-spacer />
          <v-btn
            class="header-mobile-drawer-close mr-3"
            icon
            color="#0f3684"
            @click="toggleDrawer()"
          >
            <v-icon size="35px">mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-list nav class="ml-4 fill-height">
          <v-list-item
            v-for="(item, i) in menuList"
            :key="i"
            style="min-height: inherit"
            :href="item.link"
            :ripple="false"
          >
            <v-list-item-content class="item-content">
              <p class="header-mobile-drawer-item-text">{{ item.title }}</p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <div class="header-language">
              <a
                ref="headerLanguageAnchorEn"
                class="header-language-anchor"
                style="font-size: 12px"
                @click="changeLanguage()"
                >EN</a
              >
              <span style="color: grey; font-size: 12px">&nbsp;/&nbsp;</span>
              <a
                ref="headerLanguageAnchorFr"
                class="header-language-anchor"
                style="font-size: 12px"
                @click="changeLanguage()"
                >FR</a
              >
            </div>
          </v-list-item>
          <v-list-item class="header-mobile-drawer-social-media-list mt-auto">
            <v-row>
              <v-btn icon color="#0f3684">
                <v-icon size="24px">mdi-instagram</v-icon>
              </v-btn>
              <v-btn class="ml-3" icon color="#0f3684">
                <v-icon size="24px">mdi-facebook</v-icon>
              </v-btn>
              <v-btn class="ml-3" icon color="#0f3684">
                <v-icon size="24px">mdi-twitter</v-icon>
              </v-btn>
            </v-row>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </header>
</template>

<script>
export default {
  name: "HeaderMobile",
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    menuList() {
      var result;
      if (this.$i18n.locale === "fr") {
        result = [
          {
            title: this.$t("header.section1"),
            link: "/explorer",
            page: "/explore",
          },
          {
            title: this.$t("header.section2"),
            link: "/classement",
            page: "/featured",
          },
          {
            title: this.$t("header.section3"),
            link: "/comparer",
            page: "/compare",
          },
          {
            title: this.$t("header.section4"),
            link: "/propos",
            page: "/about",
          },
          {
            title: this.$t("header.section5"),
            link: "/contact",
            page: "/contact",
          },
        ];
      } else {
        result = [
          {
            title: this.$t("header.section1"),
            link: "/explore",
            page: "/explore",
          },
          {
            title: this.$t("header.section2"),
            link: "/featured",
            page: "/featured",
          },
          {
            title: this.$t("header.section3"),
            link: "/compare",
            page: "/compare",
          },
          { title: this.$t("header.section4"), link: "/about", page: "/about" },
          {
            title: this.$t("header.section5"),
            link: "/contact",
            page: "/contact",
          },
        ];
      }
      return result;
    },
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem("lang");
    if (this.$i18n.locale == null) {
      this.$i18n.locale = "fr";
      localStorage.setItem("lang", "fr");
    }
    if (this.$i18n.locale == "en") {
      this.$refs.headerLanguageAnchorEn.classList.add(
        "header-language-anchor-active"
      );
    } else {
      this.$refs.headerLanguageAnchorFr.classList.add(
        "header-language-anchor-active"
      );
    }
  },
  methods: {
    changeLanguage() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "fr";
        localStorage.setItem("lang", "fr");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
      this.$refs.headerLanguageAnchorEn.classList.toggle(
        "header-language-anchor-active"
      );
      this.$refs.headerLanguageAnchorFr.classList.toggle(
        "header-language-anchor-active"
      );
    },
    underlinePageTitle() {
      this.anchorDOMList = this.$refs.headerAnchor;
      this.$refs.headerAnchor.forEach((element) => {
        if (element.getAttribute("page") === window.location.pathname) {
          this.currentPageTitle = element;

          this.currentPageTitle.classList.remove("header-anchor");
          this.currentPageTitle.classList.add("header-anchor-title");
        }
      });
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style scoped>
.v-list-item--link:before {
  background-color: transparent !important;
}

.header-btn::before {
  background-color: transparent !important;
}

.header-mobile-main {
  background-color: white;
}

.header-mobile-menu-burger {
  margin-right: 3%;
  margin-top: 7px;
}

.header-mobile-drawer-close {
  position: relative;
}

.header-mobile-drawer-item-text {
  color: #12368a;
  font-size: 20px;
}

.header-mobile-drawer-social-media-list {
  position: absolute;
  bottom: 2%;
}

.item-content {
  height: 35px;
}

@media screen and (orientation: landscape) {
  .header-mobile-drawer-item-text {
    color: #12368a;
    font-size: 16px;
  }

  .item-content {
    height: 28px;
  }
}
</style>
