<template>
  <!-- Footer of the platform -->
  <footer>
    <div class="footer-grid">
      <div class="footer-credits">
        <v-img
          :src="require('@/assets/images/Logo-icon-klover.png')"
          :lazy-src="require('@/assets/images/Logo-icon-klover.png')"
          width="75"
          height="75"
          contain
        />

        <div class="footer-credits-right-item">
          <span class="footer-credits-title">
            Klover
            <br />{{ $t("footer.baseline") }}
          </span>
          <div class="footer-credits-text-div">
            <div>
              <a class="footer-anchor" :href="footerLegalList[0].link">{{
                footerLegalList[0].title
              }}</a>
            </div>
            <span class="footer-credits-text"
              >Copyright &copy; 2021 Kermap</span
            >
          </div>
        </div>
      </div>

      <div class="footer-item">
        <span class="footer-item-title">{{ $t("footer.links.title") }}</span>
        <div class="footer-item-links">
          <a
            v-for="item in footerQuickLinkList"
            :key="item.title"
            class="footer-item-links-anchors"
            :href="item.link"
            >{{ item.title }}</a
          >
        </div>
      </div>

      <div class="footer-item">
        <span class="footer-item-title">{{ $t("footer.links2.title") }}</span>

        <div class="footer-item-links">
          <a
            v-for="item in footerAboutUsList"
            :key="item.title"
            class="footer-item-links-anchors"
            :href="item.link"
            >{{ item.title }}</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    footerQuickLinkList() {
      var result;
      if (this.$i18n.locale === "fr") {
        result = [
          { title: this.$t("footer.links.link1"), link: "/explorer" },
          { title: this.$t("footer.links.link2"), link: "/classement" },
          { title: this.$t("footer.links.link3"), link: "/comparer" },
          { title: this.$t("footer.links.link4"), link: "/propos" },
        ];
      } else {
        result = [
          { title: this.$t("footer.links.link1"), link: "/explore" },
          { title: this.$t("footer.links.link2"), link: "/featured" },
          { title: this.$t("footer.links.link3"), link: "/compare" },
          { title: this.$t("footer.links.link4"), link: "/about" },
        ];
      }
      return result;
    },
    footerAboutUsList() {
      return [
        { title: this.$t("footer.links2.link1"), link: "/contact" },
        { title: this.$t("footer.links2.link2"), link: "https://kermap.com" },
        {
          title: this.$t("footer.links2.link3"),
          link: "https://linkedin.com/company/kermap",
        },
        {
          title: this.$t("footer.links2.link4"),
          link: "https://twitter.com/kermap_info",
        },
      ];
    },
    footerLegalList() {
      return [{ title: this.$t("footer.privacy"), link: "/privacy" }];
    },
  },
};
</script>

<style></style>
