import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import router from "./router";
import anime from "animejs/lib/anime.min.js";
import i18n from "./i18n";
import KloverScore from "@/components/Icons/KloverScore";
import MeanDeviation from "@/components/Icons/MeanDeviation";
import Seed from "@/components/Icons/Seed";
import GreenAreaYellow from "@/components/Icons/GreenAreaYellow";
import FeaturedCitiesYellow from "@/components/Icons/FeaturedCitiesYellow";
import GreenSpacesYellow from "@/components/Icons/GreenSpacesYellow";
import GreenCapitaYellow from "@/components/Icons/GreenCapitaYellow";
import CityPopulation from "@/components/Icons/CityPopulation";
import Canopy from "@/components/Icons/Canopy";
import Walk from "@/components/Icons/Walk";
import Connectivity from "@/components/Icons/Connectivity";
import Canopy_active from "@/components/Icons/Canopy_active";
import Walk_active from "@/components/Icons/Walk_active";
import Connectivity_active from "@/components/Icons/Connectivity_active";
import Tree from "@/components/Icons/Tree";
import Tree_active from "@/components/Icons/Tree_active";
import Tree_legend from "@/components/Icons/Tree_legend";
import Density from "@/components/Icons/Density";
import GrassArea from "@/components/Icons/GrassArea";
import Area_yellow from "@/components/Icons/Area_yellow";
import VueMeta from "vue-meta";

Vue.use(Vuetify);
Vue.use(VueMeta);
export default new Vuetify({
  icons: {
    values: {
      area_yellow: {
        component: Area_yellow,
      },
      klover_score: {
        component: KloverScore,
      },
      mean_deviation: {
        component: MeanDeviation,
      },
      seed: {
        component: Seed,
      },
      green_area_yellow: {
        component: GreenAreaYellow,
      },
      featured_cities_yellow: {
        component: FeaturedCitiesYellow,
      },
      green_spaces_yellow: {
        component: GreenSpacesYellow,
      },
      green_capita_yellow: {
        component: GreenCapitaYellow,
      },
      city_population: {
        component: CityPopulation,
      },
      canopy: {
        component: Canopy,
      },
      walk: {
        component: Walk,
      },
      connectivity: {
        component: Connectivity,
      },
      tree: {
        component: Tree,
      },
      canopy_active: {
        component: Canopy_active,
      },
      walk_active: {
        component: Walk_active,
      },
      connectivity_active: {
        component: Connectivity_active,
      },
      tree_active: {
        component: Tree_active,
      },
      tree_legend: {
        component: Tree_legend,
      },
      density: {
        component: Density,
      },
      grass_area: {
        component: GrassArea,
      },
    },
  },
});

Vue.config.productionTip = false;

Vue.prototype.$anime = anime;
new Vue({
  vuetify: new Vuetify(),
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
