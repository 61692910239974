<template>
  <!-- Header of the platform -->
  <header>
    <div class="header-div">
      <v-btn
        href="/"
        text
        class="header-btn ma-2"
        width="115px"
        :ripple="false"
      >
        <v-img
          :src="require('@/assets/images/Klover_logo_mobile.png')"
          eager
          max-width="115px"
          max-height="40px"
          contain
        />
      </v-btn>
      <div class="header-anchors-div">
        <a
          v-for="(item, i) in menuList"
          :key="i"
          ref="headerAnchor"
          :href="item.link"
          class="header-anchor"
          :page="item.page"
        >
          {{ item.title }}
        </a>
      </div>

      <div class="header-language">
        <a
          ref="headerLanguageAnchorEn"
          class="header-language-anchor"
          @click="changeLanguage()"
          >EN</a
        >
        <span style="color: grey">&nbsp;/&nbsp;</span>
        <a
          ref="headerLanguageAnchorFr"
          class="header-language-anchor"
          @click="changeLanguage()"
          >FR</a
        >
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",

  data: () => ({
    // links displayed on the header
    anchorElementList: null,
    currentPageTitle: null,
  }),
  computed: {
    menuList() {
      var result;
      if (this.$i18n.locale === "fr") {
        result = [
          {
            title: this.$t("header.section1"),
            link: "/explorer",
            page: "/explore",
          },
          {
            title: this.$t("header.section2"),
            link: "/classement",
            page: "/featured",
          },
          {
            title: this.$t("header.section3"),
            link: "/comparer",
            page: "/compare",
          },
          {
            title: this.$t("header.section4"),
            link: "/propos",
            page: "/about",
          },
          {
            title: this.$t("header.section5"),
            link: "/contact",
            page: "/contact",
          },
        ];
      } else {
        result = [
          {
            title: this.$t("header.section1"),
            link: "/explore",
            page: "/explore",
          },
          {
            title: this.$t("header.section2"),
            link: "/featured",
            page: "/featured",
          },
          {
            title: this.$t("header.section3"),
            link: "/compare",
            page: "/compare",
          },
          { title: this.$t("header.section4"), link: "/about", page: "/about" },
          {
            title: this.$t("header.section5"),
            link: "/contact",
            page: "/contact",
          },
        ];
      }
      return result;
    },
  },
  mounted() {
    this.underlinePageTitle();
    this.$i18n.locale = localStorage.getItem("lang");
    if (this.$i18n.locale == null) {
      this.$i18n.locale = "fr";
      localStorage.setItem("lang", "fr");
    }
    if (this.$i18n.locale == "en") {
      this.$refs.headerLanguageAnchorEn.classList.add(
        "header-language-anchor-active"
      );
    } else {
      this.$refs.headerLanguageAnchorFr.classList.add(
        "header-language-anchor-active"
      );
    }
  },
  methods: {
    underlinePageTitle() {
      this.anchorDOMList = this.$refs.headerAnchor;
      this.$refs.headerAnchor.forEach((element) => {
        if (element.getAttribute("page") === window.location.pathname) {
          this.currentPageTitle = element;

          this.currentPageTitle.classList.remove("header-anchor");
          this.currentPageTitle.classList.add("header-anchor-title");
        }
      });
    },
    changeLanguage() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "fr";
        localStorage.setItem("lang", "fr");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
      this.$refs.headerLanguageAnchorEn.classList.toggle(
        "header-language-anchor-active"
      );
      this.$refs.headerLanguageAnchorFr.classList.toggle(
        "header-language-anchor-active"
      );
    },
  },
};
</script>

<style scoped>
.header-btn::before {
  background-color: transparent !important;
}
</style>
